<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Bannners is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>Unlock the ability to design custom banners in any size or shape, elevating the engagement of your creative campaigns.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/1409553168281102402/bannners" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="kaleidoscope-img" src="assets/img/bannnerss.png" alt="">

        <h2>Bannners Plugin</h2>
        <p>Now you can easily create custom banners in any size and shape to make your creative campaigns more engaging.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Bannners</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Lack of orientation options for banner generation.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Difficulty in aligning elements.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Challenging to resize banners effortlessly.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Bannners</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Generate in portrait and landscape orientations.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Align elements to suit your design needs.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Resize banners effortlessly.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Design Stunning Banners in Figma: Resize, Align, and Customise with the Bannners Plugin</h3>
				<p>Create stunning banners in Figma with ease using the Bannners Plugin. Resize, align, and customise your designs with precision, whether in portrait or landscape orientations. Streamline your workflow and craft professional-quality banners in minutes!</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/bannners.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Streamline Your Banner Creation: Align, resize, and design.</h2>
        <p>Check the plugin demo and give it a try to make your banners clearer, aligned, and resized if needed.</p>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-video-section multiple-videos-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/alignment.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Assets Alignment</h2>
        <p>Check the plugin demo and give it a try to align your assets perfectly.</p>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-video-section multiple-videos-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/resize.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Resizing your assets</h2>
        <p>Check the plugin demo and give it a try to resize your assets accurately and position them correctly.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-content-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Generate banners in portrait and landscape orientations to suit all your design requirements.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/1409553168281102402/bannners" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/iso.png" alt="">
              <h2>Iso All</h2>
            </div>
            <p>Iso All seems to be a tool or feature that simplifies and enhance the creation of isometric designs.</p>
            <a routerLink="/iso-all"><button type="button" name="button">Try Iso All <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Workspace</h2>
            </div>
            <p>Workspace is a cloud storage, where you can upload your assets to numerous boards and access them through plugin.</p>
            <a routerLink="/figma"><button type="button" name="button">Try Workspace <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is the Bannners plugin for Figma?</h2>
					<p>The Banners plugin for Figma allows you to easily create, resize, and align banners in both portrait and landscape orientations. It helps streamline the design process for web and mobile banners, ensuring they are perfectly aligned and sized according to your needs.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I use this plugin to design banners in different sizes?</h2>
					<p>Yes, the plugin allows you to resize banners to fit various dimensions, whether you’re creating ads, social media posts, or website headers. You can adjust the size of your banners as needed.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Does the plugin support both portrait and landscape orientations?</h2>
					<p>Absolutely! The plugin supports generating banners in both portrait and landscape orientations, making it versatile for a wide range of design projects.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I resize banners while maintaining the correct aspect ratio?</h2>
					<p>Yes, the plugin helps you resize banners while keeping the correct proportions intact, so you won’t have to worry about distorting your designs.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is this plugin easy to use for beginners?</h2>
					<p>Yes! The plugin is designed with simplicity in mind, offering an easy-to-navigate interface that allows both beginners and experienced designers to create and manage banners without hassle.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>Can I use the plugin for social media banners and ads?</h2>
					<p>Yes, the plugin is perfect for designing social media banners, online ads, and other digital content in various sizes and orientations.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
