<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Crafttor is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>Elevate your design efficiency and power up your designs with the Figma plugin. Design smarter and faster with this powerful tool.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/902086023682633830/Crafttor" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="craft-figma-img" src="assets/img/craft-figma.png" alt="">

        <h2>Workspace & Marketplace Plugin</h2>
        <p>Access Workspace & Marketplace assets directly from the Figma plugin. Say goodbye to the hassle of re-uploading and downloading files, and hello to a hassle-free workflow.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Crafttor</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            No way for DAM to upload/download from plugins.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Limited or paid access to Marketplace assets.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            No Content Library within DAM plugin.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Crafttor</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            DAM's upload/download from within the plugins.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Free access to Marketplace assets.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Content Library within DAM plugin
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Streamline asset organisation and retrieval with Crafttor's powerful tools.</h3>
				<p>With powerful tools for seamless cloud-based storage and easy access, you can manage your creative resources more efficiently, enabling smoother collaboration and faster project completion.</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/plugin-tagging.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Effortlessly Tag Your Assets in the Plugin</h2>
        <p>Organise and streamline your workflow by easily tagging assets within the plugin.</p>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-video-section" style="margin: 80px 0px;">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/plugin-version.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Seamless Versioning for Your Assets in the Plugin</h2>
        <p>Keep track of asset changes and manage versions effortlessly within the plugin.</p>
			</div>
		</div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Design Smarter, Faster with Crafttor's collaborative Figma Tools</h3>

        <div class="figma-list-points">
          <ul>
            <li><img src="assets/img/tick-green.svg" alt=""> Upload directly from Figma plugin & start designing your artwork.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Switch between Workspace and Marketplace.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Select Marketplace illustrations categories.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Pin or Unpin boards.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Add tags to your assets.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Crafttor' Figma Marketplace/Workspace</h3>
				<p>Offer seamless access to creative assets and streamlined cloud-based asset management for efficient design workflows.</p>
      </div>

      <ul>
        <li>
          <div class="plugins-data">
            <h1 class="figma-count-txt">28.2K</h1>
            <h4>Installs</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
  					<h1 class="sketch-count-txt">DAM</h1>
  					<h4>Support</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
  					<h1 class="illustrator-count-txt">3D</h1>
  					<h4>Marketplace</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="figma-content-section" style="padding: 0px 0px 120px;">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>


<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Create & customize illustrations with Figma plugin to bring your<br>
						story to life and inspire people around the globe.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/902086023682633830/Crafttor" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/bannner.png" alt="">
              <h2>Bannners</h2>
            </div>
            <p>Now you can easily create custom banners in any size and shape to make your creative campaigns more engaging.<br></p>
            <a routerLink="/bannners"><button type="button" name="button">Try Bannners <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/trans.png" alt="">
              <h2>Translate AI</h2>
            </div>
            <p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.<br></p>
            <a routerLink="/translate-ai"><button type="button" name="button">Try Translate AI <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Figma Plugin?</h2>
					<p>Figma Plugin is a web-based prototyping tool and a vector graphics editor for teams to collaborate and bring ideas to life. You can integrate designs, illustrations, and other web elements offered by other brands to create your websites, UI/UX design, and a lot more.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How to use Figma plugins?</h2>
					<p>Figma is available as a cloud-based, in-browser plugin and is universally available across all platforms. Register for free to start your creative journey. You can install plugins for illustrations, icons, mobile designs, web designs, and a lot more. </p>
					<hr>
				</div>
			</div>

			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Who should use Figma Plugins?</h2>
					<p>Figma is a collaborative tool with a free plan for anyone working in the digital space. Although it’s suitable for both individual projects and team endeavors, the browser-based application saves your work in real-time. Other prototyping tools either don’t have the same ability or entirely lack this essential feature.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>How can I access Crafftor on Figma?</h2>
					<p>To access our illustrations on Figma, create a free account and choose the starter pack with 30-day version history and unlimited cloud storage. Go to Menu > Plugins > Browse Plugins in Community and search for Crafftor. Install the plugin and start using our illustrations for your projects.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
