<!-- Hero Section -->
<section class="hero-section updated-hero">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content text-center">
					<h1>One Place For All Your Creative Needs</h1>
					<h2>organise, share, collaborate </h2>
					<div class="btn-wrapper">

						<a href="javascript:;" class="button" (click)="openWorkspaceLogin()" *ngIf="!currentUser">Sign in for Free</a>

						<a href="javascript:;" class="button gray-btn" (click)="openLetsTalkDialog(letsTalk)" *ngIf="!currentUser">Book Demo</a>
						<a style="margin-left: auto; margin-right: auto;" href="javascript:;" class="button gray-btn" (click)="openLetsTalkDialog(letsTalk)" *ngIf="currentUser">Book Demo</a>
					</div>
					<span>No credit card required</span>
				</div>
			</div>
		</div>
	</div>
	<div class="animation-wrapper">
		<div class="animation-box" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800" style="background-image: 'assets/img/nim2.png'">
			<img src="assets/img/nim2.png" class="img-fluid">
    </div>
		<div class="animation-box" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800">
			<img src="assets/img/nim1.png" class="img-fluid">
		</div>
		<div class="animation-box" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
			<img src="assets/img/nim3.png" class="img-fluid">
		</div>
	</div>
</section>

<!-- Moving Logos  Section -->
<section class="moving-section">
	<div class="sec-title">
		Trusted by
	</div>
	<div class="moving-logo-wrapper">
		<ul>
			<li>
				<img src="assets/img/airtel.svg" alt="">
			</li>
			<li>
				<img src="assets/img/licious.svg" alt="">
			</li>
			<li>
				<img src="assets/img/byju.svg" alt="">
			</li>
			<li>
				<img src="assets/img/wheels.svg" alt="">
			</li>
			<li>
				<img src="assets/img/deloitte.svg" alt="">
			</li>
			<li>
				<img src="assets/img/flip.svg" alt="">
			</li>
			<li>
				<img src="assets/img/innovaccer.svg" alt="">
			</li>
			<li>
				<img src="assets/img/thoughtworks.svg" alt="">
			</li>
			<li>
				<img src="assets/img/yellow.ai.svg" alt="">
			</li>
			<li>
				<img src="assets/img/airtel.svg" alt="">
			</li>
			<li>
				<img src="assets/img/licious.svg" alt="">
			</li>
			<li>
				<img src="assets/img/byju.svg" alt="">
			</li>
			<li>
				<img src="assets/img/wheels.svg" alt="">
			</li>
			<li>
				<img src="assets/img/deloitte.svg" alt="">
			</li>
			<li>
				<img src="assets/img/flip.svg" alt="">
			</li>
			<li>
				<img src="assets/img/innovaccer.svg" alt="">
			</li>
			<li>
				<img src="assets/img/thoughtworks.svg" alt="">
			</li>
			<li>
				<img src="assets/img/yellow.ai.svg" alt="">
			</li>
		</ul>
	</div>
</section>

<!-- Vector Section -->
<section class="reversible-section">
	<div class="container">
		<div class="row">
			<div class="col-md-5">
			<h2>Workspace helps teams boost their productivity</h2>
			<p>Crafttor Workspace serves as a central repository for all of your assets, files and your overall project work. You can access it from anywhere and at any time without leaving your workspace.</p>
			<p class="learn-more">
				<a href="https://workspace.crafttor.com" target="_blank">Learn more
					<svg _ngcontent-tbs-c85="" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-tbs-c85="" d="M12.1251 19L19 12L12.1251 5" stroke="#2d7cff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-tbs-c85="" d="M5 12L18.1255 12" stroke="#2d7cff" stroke-width="1.5" stroke-linecap="round"></path></svg>
				</a></p>
			</div>
			<div class="col-md-1">
			</div>
			<div class="col-md-6">
				<video width="600" height="475" autoplay loop [muted]="'muted'" class="first_video">
					<source src="assets/img/workspace_prev.mp4" type="video/mp4">
				</video>
			</div>
		</div>
	</div>
</section>

<section class="reversible-section">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<video width="600" height="475" [muted]="'muted'" loop autoplay class="second_video">
					<source src="assets/img/mkt_prev.mp4" type="video/mp4">
				</video>
			</div>

			<div class="col-md-1">
			</div>
			<div class="col-md-5">
				<h2>Marketplace: Get any illustration or icon</h2>
				<p>Make your work more appealing with the mix & match combinations of unlimited illustration styles. Discover boundaries between reality and imagination and unfold the enchanting scenes of mind blowing revolution and reveal it loud.</p>
				<p class="learn-more">
					<a routerLink="/marketplace">Learn more
						<svg _ngcontent-tbs-c85="" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-tbs-c85="" d="M12.1251 19L19 12L12.1251 5" stroke="#2d7cff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-tbs-c85="" d="M5 12L18.1255 12" stroke="#2d7cff" stroke-width="1.5" stroke-linecap="round"></path></svg>
					</a>
				</p>
			</div>
		</div>
	</div>
</section>

<section class="reversible-section">
	<div class="container">
		<div class="row">
			<div class="col-md-5">
			<h2>Plugins help you access any asset from anywhere</h2>
			<p>Introducing a revolutionary addition for you to elevate your creative projects to new dimensions. These versatile plugins by Crafttor seamlessly integrate with your work, providing a dynamic range of features to enhance your creations. With the help of plugins, you can access your organisation's assets from anywhere and anytime</p>
			<p class="learn-more">
				<a href="javascript:;" routerLink="/smart-plugins">Learn more
					<svg _ngcontent-tbs-c85="" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-tbs-c85="" d="M12.1251 19L19 12L12.1251 5" stroke="#2d7cff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-tbs-c85="" d="M5 12L18.1255 12" stroke="#2d7cff" stroke-width="1.5" stroke-linecap="round"></path></svg>
				</a></p>
			</div>
			<div class="col-md-1">
			</div>
			<div class="col-md-6">
				<video width="600" height="475" autoplay loop [muted]="'muted'">
					<source src="assets/img/plugin_prev.mp4" type="video/mp4">
				</video>
			</div>
		</div>
	</div>
</section>

<section class="team-carousel team-new-reviews" style="background: #f4f4f4;">
	<!-- <div class="pink-background"></div> -->
	<div class="carousel-review-container">
		<h5 class="carousel-review-heading">Real Reviews from</h5>
		<div class="carousel-subheading-review-back">
			<h2 class="carousel-review-subheading">Real Customers</h2>
		</div>
		<div class="carousel-review-container">
			<div class="carousel-wrapper" [ngStyle]="{'transform': 'translateX(-' + currentIndex * (100 / visibleItems) + '%)'}">
				<div class="carousel-review-item" *ngFor="let item of items">
					<div class="carousel-review-content">
						<div class="review-icon-box">
							<img [src]="item.icon" alt="">
	          </div>
						<p class="review-main-txt">"{{item.desc}}"</p>

						<div class="review-img-txt-section">
							<hr>
							<div class="review-img-txt">
								<img class="user-review-image" [src]="item.image" alt="User Image" />
								<div class="carousel-review-text">
									<h3 class="user-review-name">{{ item.name }}</h3>
									<p class="user-review-designation">Founder: <b>{{ item.designation }}</b></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button class="nav-button left" *ngIf="currentIndex > 0" (click)="prevSlide()">&#10094;</button>
			<button class="nav-button right" *ngIf="currentIndex < items.length - visibleItems" (click)="nextSlide()">&#10095;</button>
		</div>
		<img class="side-image" src="assets/img/speaker.svg" alt="Side Image" />

		<img class="left-side-image" src="assets/img/pink-side.svg" alt="Side Image" />
	</div>
</section>

<!-- Create Anything Section -->
<section class="new-blog-section">
<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<h2>Latest Blogs</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-4" *ngFor="let blog of recentBlogs">
			<a href="#" class="inner-box blog-box">
				<div class="thumb">
					<a href="javascript:;" [routerLink]="['/blog', blog.url_slug]"><img [src]="blog.image_sqr" class="zoom-image"></a>
				</div>
				<div class="meta-data">
					<!-- <span>{{blog.date}}</span>  -->
					<span class="tag">{{blog.author}}</span>
				</div>
				<h4><a href="javascript:;" [routerLink]="['/blog', blog.url_slug]">{{blog.title}}</a></h4>
			</a>
		</div>
	</div>
	</div>
</section>

<!-- Create Anything Section -->
<section class="contact-section-new">
<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<h2>Make your team more productive <span>with Crafttor</span></h2>
			<div class="inner-content">
				<form class="form-horizontal" [formGroup]="letsTalkFormNew" (submit)="bookDemo(selectDate)">
					<div class="form-group">
						<div class="col-md-12">
						<label class="control-label" for="name">Name</label>
						<input formControlName="fullName" id="fullName" name="textinput" type="text" placeholder="Your Full Name" class="form-control input-md" required="">
						<!-- <span class="text-danger error-text"
	          	*ngIf="(letsTalkFormNew.controls.fullName.touched || submitted) && letsTalkFormNew.controls.fullName.errors?.required">
	          	Full name is required
	        	</span> -->
						</div>
					</div>

					<!-- Text input-->
					<div class="form-group">
						<div class="col-md-12">
						<label class="control-label" for="email">Email</label>
						<input formControlName="fullEmail" id="fullEmail" name="textinput" type="email" placeholder="Business Email" class="form-control input-md" required="">
						<!-- <span class="text-danger error-text"
	          	*ngIf="(letsTalkFormNew.controls.fullEmail.touched || submitted) && letsTalkFormNew.controls.fullEmail.errors?.required">
	          	Email is required
	        	</span>
	        	<span class="text-danger error-text"
	        		*ngIf="(letsTalkFormNew.controls.fullEmail.touched || submitted) && letsTalkFormNew.controls.fullEmail.errors?.pattern">
	        		Email is not valid
	      		</span> -->
						</div>
					</div>

					<!-- Select -->
					<div class="form-group">
						<div class="col-md-12">
						<label class="control-label" for="company-size">Company Size</label>
						<select (change)="selectCompanySize($event)" class=" input-md" id="companySize">
							<option value="0-5">0-5</option>
							<option value="5-10">5-10</option>
							<option value="10-20">10-20</option>
							<option value="20-50">20-50</option>
							<option value="50-100">50-100</option>
							<option value="more than 100">more than 100</option>
						</select>
						</div>
					</div>

					<!-- Select -->
					<div class="form-group">
						<div class="col-md-12">
						<label class="control-label" for="option">How did you hear about us? <span>(optional)</span></label>
						<select (change)="selectHeardAbout($event)" class=" input-md" id="heardAbout">
							<option value="Google Search">Google Search</option>
							<option value="LinkedIn">LinkedIn</option>
							<option value="Through a friend">Through a friend</option>
							<option value="Social Media">Social Media</option>
							<option value="Others">Others</option>
						</select>
						</div>
					</div>

					<!-- Button -->
					<div class="form-group">
						<div class="col-md-12">
						<button id="singlebutton" name="singlebutton button" class="btn btn-primary">Book Demo</button>
						<span class="terms">By submitting the form, you agree with our <a routerLink="/privacy-terms" target="_blank">Terms & Conditions</a>, and <a routerLink="/privacy-terms" target="_blank">Privacy</a>.</span>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
</section>

<ng-template #letsTalk let-letsTalk>
	<div class="letstalk-assests">
    <div class="modal-dialog modal-dialog-centered sign-up" role="document">
      <div class="modal-content">
        <div class="modal-body">
        <button type="button" class="close" (click)="letsTalk.close()">
            <img src="assets/img/cross.svg" alt="close">
        </button>
        <div class="modal-body">
      		<h2>Let's talk!</h2>
      		<p>Schedule a free demo today. We will show you a demo of how Crafttor Workspace works.</p>
      	<div class="form-wrapper">
        <form class="row g-3" [formGroup]="letsTalkForm" (ngSubmit)="submitContactUs(selectDate)">
          <div class="col-md-6">
          <label class="form-label">First Name<span>*</span></label>
          <input id="textinput" formControlName="name" type="text" placeholder="Brian" class="form-control input-md" required>
          <span class="text-danger error-text"
          *ngIf="(letsTalkForm.controls.name.touched || submitted) && letsTalkForm.controls.name.errors?.required">
          First name is required
        </span>
          </div>
          <div class="col-md-6">
          <label class="form-label">Last Name<span>*</span></label>
          <input id="textinput" formControlName="lname" type="text" placeholder="Storm" class="form-control input-md" required>
          <span class="text-danger error-text"
          *ngIf="(letsTalkForm.controls.lname.touched || submitted) && letsTalkForm.controls.lname.errors?.required">
          Last name is required
        </span>
          </div>
          <div class="col-12">
          <label for="businessEmail" class="form-label">Business Email<span>*</span></label>
          <input id="textinput" formControlName="email" type="email" placeholder="abc@crafttor.com" class="form-control input-md" >
          <span class="text-danger error-text"
          *ngIf="(letsTalkForm.controls.email.touched || submitted) && letsTalkForm.controls.email.errors?.required">
          Email is required
        </span>
        <span class="text-danger error-text"
        *ngIf="(letsTalkForm.controls.email.touched || submitted) && letsTalkForm.controls.email.errors?.pattern">
        Email is not valid
      </span>
          </div>
          <div class="col-12">
          <label for="company" class="form-label">Company<span>*</span></label>
          <input id="textinput" formControlName="company" type="text" placeholder="Crafttor" class="form-control input-md" >
					<span class="text-danger error-text"
          *ngIf="(letsTalkForm.controls.company.touched || submitted) && letsTalkForm.controls.company.errors?.required">
          Company name is required
        </span>
          </div>

          <div class="col-md-12" style="display: none;">
            <label class="form-label" for="textarea">Message</label>
            <input id="textinput" formControlName="message" type="text" class="form-control input-md" required>
            <span class="text-danger error-text"
            *ngIf="(letsTalkForm.controls.message.touched || submitted) && letsTalkForm.controls.message.errors?.required">
            Message is required
          </span>
          </div>


          <div class="col-12">
          <button style="height: 40px;" id="singlebutton" name="singlebutton button" class="btn btn-primary" *ngIf="!showLeaveLoader">Submit</button>

					<button style="height: 40px;" id="singlebutton" name="singlebutton button" class="btn btn-primary" *ngIf="showLeaveLoader">
						<img src="assets/img/leave-loader.gif" alt="" style="height: 15px;">
					</button>
          </div>
        </form>
      </div>
      <p>By Submiting the form, You agree to our <a routerLink="/privacy-terms" target="_blank">Terms of Service</a> and acknowledge our <a routerLink="/privacy-terms" target="_blank">Privacy Policy</a>.</p>
        </div>
      </div>
    	</div>
    </div>
	</div>
</ng-template>

<ng-template #selectDate let-selectDate>
	<div class="date-section">
    <div class="modal-dialog modal-dialog-centered select-time-popup" role="document">
      <div class="modal-content">
        <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <img src="assets/img/cross.svg" (click)="selectDate.close()">
          </button>
        <div class="modal-body">
      <h6>{{thankYouText}}</h6>
			<h6>(IST)</h6>
      <div class="form-wrapper">
				<ul class="date-listing" *ngFor="let slots of availableSlots | slice:1:5; let i = index">
					<li *ngIf="showDate">
						<div class="date-list" (click)="selectDateSales(slots.date, slots.day, slots.month)">
							<!-- <div class="date-list-day" style="background: #f9f9f9">
								<a href="#">{{slots.day}}</a>
							</div> -->
							<div class="">
								<a href="javascript:;">{{slots.month}} {{slots.date1}}</a>
							</div>
							<!-- <a href="javascript:;">{{slots.month}}</a> -->
						</div>
					</li>
				</ul>

				<div *ngIf="!showDate" class="selected-date">
					<div class="date-list-days">
						<a href="javascript:;">{{shownDay}}</a>
					</div>
					<div style="padding-top:10px;" class="">
						<a href="javascript:;">{{shownDate}}</a>
					</div>
					<!-- <a href="javascript:;">{{shownMonth}}</a> -->
				</div>

				<p *ngIf="!showDate" (click)="changeDate()" class="change-date-txt">Change Date</p>

				<h6 style="margin-top:20px;" *ngIf="showTime">What time works?</h6>

				<ul *ngFor="let time of availableTimeSlots; let i = index">
					<li *ngIf="showTime">
						<div class="time-list" (click)="selectTimeSales(time)">
							<div style="text-align: center;" class="">
								<a href="javascript:;">{{time}}</a>
							</div>
						</div>
					</li>
				</ul>
				<div *ngIf="showFinalTime" style="text-align: center;" class="selected-time">
					<a href="javascript:;">{{shownTime}}</a>
				</div>
				<p *ngIf="!showTime" (click)="changeTime()" class="change-time-txt"></p>
				<button id="singlebutton" name="singlebutton button" class="btn btn-primary" *ngIf="!timeSelected && !dateSelected && showSubmitBtn" (click)="contactSalesCall(thankyou)">Submit</button>
				<button id="singlebutton" name="singlebutton button" class="btn btn-primary" *ngIf="!timeSelected && dateSelected && showSubmitBtn" (click)="contactSalesCall(thankyou)">Submit</button>
				<button id="singlebutton" name="singlebutton button" class="btn btn-primary" *ngIf="timeSelected && !dateSelected && showSubmitBtn" (click)="contactSalesCall(thankyou)">Submit</button>
				<button id="singlebutton" name="singlebutton button" class="btn btn-primary" (click)="contactSalesCall(thankyou); selectDate.close()" *ngIf="timeSelected && dateSelected && showSubmitBtn">Submit</button>
				<p *ngIf="showSelectionMessage" class="text-danger error-text" style="text-align: center; margin-top:10px; font-weight: 600">{{showSelectedMsg}}</p>
      </div>
      <p style="text-align: center;">By Submiting the form, You agree to our <a routerLink="/privacy-terms" target="_blank">Terms of Service</a> and acknowledge our <a routerLink="/privacy-terms" target="_blank">Privacy Policy</a>.</p>
        </div>
      </div>
    </div>
  </div>
	</div>
</ng-template>

<ng-template #showloader let-showloader>
  <div class="modal-dialog modal-dialog-centered show-loader" role="document">
		<div class="modal-body" style="text-align: center;">
			<img class="main-loader-img" src="assets/img/loader.svg" alt="">
			<h6>Please wait</h6>
			<p>Confirming your meeting time..</p>
		</div>
	</div>
</ng-template>

<div
	style="width: 100%;
	background-color: rgba(0,0,0, .9);
  padding-top:200px;"
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':showloaderStyle}">
	<div class="modal-dialog-style modal-dialog-centered-style" role="document" style="height: 100px;
    width: 35%; margin: 0 auto; text-align: center;">
		<div class="modal-content" style="background-color: rgb(255,255,255); border-radius: 10px;">
			<div class="modal-body">
				<img class="main-loader-img" style="width: 140px;" src="assets/img/loader.svg" alt="">
			  <h6>Please wait</h6>
			  <p>Confirming your meeting time</p>
			</div>
		</div>
	</div>
</div>


<ng-template #thankyou let-thankyou>
	<div class="thnx-section">
  <div class="modal-dialog modal-dialog-centered thanks-popup" role="document">
    <div class="modal-content">
      <div class="modal-body">
    		<h5>Thank you <br>Your meeting is confirmed. </h5>
    			<div class="conver-grid">
      			<div class="img-wrapper">
        			<img src="" >
        			<img src="assets/img/tick.png" class="tick">
      			</div>
      		<p>You are meeting with</p>
      		<h4>Sandeep Virk</h4>
    			</div>
    	<div class="invitation-wrapper">
      	<div class="">You will receive a meeting invitation shortly</div>
      		<table>
        	<tbody>
          	<tr>
            	<th>Event</th>
            	<td>Crafttor intro with Sandeep</td>
          	</tr>
          	<tr>
            	<th>Guest</th>
            	<td>{{userName}}<span><a>{{userEmail}}</a></span></td>
          	</tr>
        	</tbody>
      	</table>
    	</div>
			<button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="width: 100%;
    	margin-top: 20px;" (click)="thankyou.close()">Close</button>
  	</div>
	</div>
</div>
</div>
</ng-template>

<ng-template #loginmodal let-loginmodal>
  <div class="modal-dialog modal-dialog-centered login-modal home-login-modal" role="document">
    <div class="modal-content">
			<div class="" style="margin-left: 420px;">
				<button type="button" class="closed" data-dismiss="modal" aria-label="Close">
					<img src="assets/img/cross.svg" (click)="loginmodal.close()">
	      </button>
			</div>
      <div class="modal-body login-dialog">
        <h3>{{loginSignUpText}}</h3>
        <div class="button-wrapper">
          <a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
          <a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>

					<div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>

          <div class="container" *ngIf="login">
            <form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
              *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
              Email is not valid
            </span>
            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
              <span class="text-danger error-text"
              *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
              Password is required
            </span>

              <label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
              clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

              <button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="!showLeaveLoader">Submit</button>

              <p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
              <!-- <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a> -->

              <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
              </form>
            </div>

            <div class="container" *ngIf="emailVerify">
              <form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
            *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
            Email is not valid
          </span>
          <button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="!showLeaveLoader">Get OTP</button>

          <p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
          </form>
          </div>

          <div class="container" *ngIf="signup">
            <form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
              <input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
              <input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
              <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
              First Name is required
            </span>
              <span class="text-danger error-text" style="float: right;"
              *ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
              Last Name is required
            </span>
            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
            <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
              Password is required
            </span>

            <input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
            <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
              OTP is required
            </span>
            <button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="!showLeaveLoader">Submit</button>

            <p class="below-txt">Already have an account? <a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed"><b>Login</b></a></p>
          </form>
        </div>

        <div class="container" *ngIf="forgotpassword">
          <form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
            <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
            <span class="text-danger error-text"
            *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
            *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
              Email is not valid
            </span>
            <button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="!showLeaveLoader">Get OTP</button>

            <p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
            </form>
          </div>

          <div class="container" *ngIf="enterOtp">
            <form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
              <input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
              <span class="text-danger error-text"
              *ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
              Enter OTP
            </span>

            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
            <span class="text-danger error-text"
            *ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
            Enter new password
          </span>
          <input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
          <span class="text-danger error-text"
            *ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
            Re-enter password
          </span>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary login-submit-btn" *ngIf="!showLeaveLoader">Submit</button>

              <p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
              </form>
            </div>

          <p>By Login, You agree to our <a href="javascript:;" (click)="loginmodal.close()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="loginmodal.close()"
            [routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
