import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-bannners',
  templateUrl: './bannners.component.html',
  styleUrls: ['./bannners.component.css']
})
export class BannnersComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  title = 'Bannners Plugin | Crafttor';

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Now you can easily create custom banners in any size and shape to make your creative campaigns more engaging. Please try and let us know your feedback @ info@crafttor.com'},
      { name: 'keywords', content: 'bannners plugin, plugin, bannners' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }
}
