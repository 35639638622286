<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Image to Figma is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>Image to Figma is becoming a designer's favorite tool, helping them make images or screenshots editable within Figma designs.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/1417763374654877658/image-to-figma-design-beta" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="kaleidoscope-img" src="assets/img/img-figma.png" alt="">

        <h2>Image to Figma Plugin</h2>
        <p>Converts any image & screenshot into fully editable Figma designs. Right now it's a <i><b style="background-color: #ffd364; padding: 2px 6px; border-radius: 4px; color: #000000;">BETA</b></i> version.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Image to Figma</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Manual recreation of design elements.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Time-consuming alignment with images.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Difficulty in extracting complex visuals.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Image to Figma</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Automatic conversion into editable layers.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Faster design process with less effort.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Improved precision for extracted elements.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Extract editable layers from images to Figma using Image to Figma Plugin</h3>
				<p>Image to Figma Design plugin quickly converts any image or screenshot into editable Figma layers, saving designers time and improving efficiency. It’s an essential tool for designers looking to streamline the process of working with visual assets.</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/img-fig.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Boost Your Visuals: Here's the Features Walkthrough</h2>
        <p>Check the plugin demo and give it a try to make images into editable Figma designs.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-content-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Turn your images into fully editable Figma designs in just a few clicks with the Image to Figma Design plugin.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/1417763374654877658/image-to-figma-design-beta" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/iso.png" alt="">
              <h2>Iso All</h2>
            </div>
            <p>Iso All seems to be a tool or feature that simplifies and enhance the creation of isometric designs.</p>
            <a routerLink="/iso-all"><button type="button" name="button">Try Iso All <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/bannner.png" alt="">
              <h2>Bannners</h2>
            </div>
            <p>Now you can easily create custom banners in any size and shape to make your creative campaigns more engaging.<br></p>
            <a routerLink="/bannners"><button type="button" name="button">Try Bannners <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What does the Image to Figma Design plugin do?</h2>
					<p>The plugin converts any image or screenshot into fully editable Figma designs, allowing you to modify the elements directly within Figma.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which types of images work with this plugin?</h2>
					<p>It supports standard image formats like JPEG, PNG, and screenshots, making them editable within Figma.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I edit every element in the converted image?</h2>
					<p>Yes, the plugin extracts individual elements from the image or screenshot, converting them into editable layers in your Figma design.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Does the plugin impact the original image quality?</h2>
					<p>The plugin preserves the quality of the image while converting it into editable layers, but the accuracy of the conversion depends on the complexity of the image.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is there any limit to how many images I can convert?</h2>
					<p>Currently, the plugin does not limit the number of images you can convert, but processing time may increase with larger or more complex images.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How secure is my data when using the plugin?</h2>
					<p>All data remains within your Figma file, ensuring security and privacy.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>Is there a file size limit for uploading images?</h2>
					<p>While there's no strict limit, larger files may take longer to process depending on the image complexity.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
