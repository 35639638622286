<!-- Search Section -->
<section class="search-section profile-heading" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-box">
					<h1>Premium Membership</h1>
					<p>Choose a plan that fits you and your needs.</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="letsmake-section pricing-pre" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="letsmake-box">

					<div class="switcher">
						<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="radioYearly" (click)="yearly();radioMontly=false; radioYearly=true;">
						<label for="yearly">Yearly</label>
						<input type="radio" name="subscription" value="monthly" id="monthly" [checked]="radioMontly"  (click)="monthly(); radioYearly=false; radioMontly=true;">
						<label for="monthly">Monthly</label>
					</div>
				<img src="assets/img/saving.svg" alt="download high quality vector illustrations">
					<div class="price-card">
						<div class="inner-card" *ngFor="let membership of memberships">
							<div class="card-box">
								<img src="assets/img/{{membership.image}}" alt="best quality svg illustrations">
								<h3>{{membership.text}}</h3>
								<!-- <span class="price">{{membership.amount_text.now}}</span> -->
								<!-- <span class="price" *ngIf="isIndia">{{membership.amount_text.INR.now}}</span> -->
								<span class="price">{{membership.amount_text.USD.now}}</span>
								<span class="main-price" *ngIf="membership.text == 'Free'">Free Access</span>

								<!-- <span class="main-price" *ngIf="membership.text != 'Free' && isIndia">Earlier <span><s>{{membership.amount_text.INR.earlier}}</s></span></span> -->
								<span class="main-price" *ngIf="membership.text != 'Free'">Earlier <span><s>{{membership.amount_text.USD.earlier}}</s></span></span>
								<ul>
									<li *ngFor="let li of membership.li">{{li}}</li>
								</ul>
								<a href="javascript:;" class="button gray-btn" routerLink="/search" *ngIf="membership.text == 'Free'" (click)="downloadIllus()">Download</a>
								<a href="javascript:;" class="button buy-now-membership" *ngIf="membership.text != 'Free'" (click)="showPayment(membership)">Buy now</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- {popup-content - end } -->
</section>

<section class="cart_sec" *ngIf="paymentSelected">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper">
					<div class="user-info">
						<div class="member-name"></div>
						<h1>Checkout</h1>
						<div class="back-area" (click)="backToDetails()">
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							    <title>Back</title>
							    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							        <g id="DAM-Pricing-Copy-2" transform="translate(-40.000000, -28.000000)">
							            <g id="Back" transform="translate(40.000000, 28.000000)">
							                <rect id="Rectangle-8" x="0" y="0" width="24" height="24"></rect>
							                <g id="Group-7" stroke-linecap="round" stroke-linejoin="round" transform="translate(3.000000, 6.000000)" stroke="#000000" stroke-width="1.5">
							                    <line x1="0" y1="6" x2="17.1428571" y2="6" id="Stroke-1"></line>
							                    <polyline id="Path-2" points="5.4 0 0 5.9256575 5.27460736 12"></polyline>
							                </g>
							            </g>
							        </g>
							    </g>
							</svg>
							<p style="margin-left: 8px;">Back</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="cart_sec-details" *ngIf="paymentSelected">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 cart-illus-img">
				<img src="assets/img/{{selectedMembership.image}}" alt="" class="item-img">

				<ul class="checkout-ul">
					<li class="">
						<div class="checkout-main-details" style="position: relative;">
							<i class="fa fa-check" aria-hidden="true"></i>
							<h6 class="illus-detail-txt">All illustrations of <b>{{selectedMembership.text}}</b>.</h6>
						</div>
					</li>
					<li class="">
						<div class="checkout-main-details" style="position: relative;">
							<i class="fa fa-check" aria-hidden="true"></i>
							<h6 class="illus-detail-txt">All updates & features of <b>{{selectedMembership.text}}</b>.</h6>
						</div>
					</li>
					<li class="note-area">
						<div class="note-details" style="position: relative;">
							<span><b>*Note: </b> All taxes are included in the total amount.</span>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-sm-5">
				<div class="payment-section">
					<h5>Your order summary</h5>
					<hr>
					<table class="shop_table" cellspacing="0">
						<tbody>
							<tr class="month-year-area">
								<td *ngIf="noShowSavings" class="yearly-monthly">
									<input type="radio" name="subscription" value="monthly" id="monthly" [checked]="detailsMonthly">
									<label for="monthly">Monthly<div class="badge">Save 0%</div></label>
								</td>
								<td *ngIf="!noShowSavings" class="yearly-monthly">
									<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="detailsYearly">
									<label for="yearly">Yearly<div class="badge">Save 20%</div></label>
								</td>
								<td>
									<div class="price" style="margin-top: -6px;">{{selectedMembership.amount_text.USD.now}}</div>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="coupon-box">
								<a class="link-btn" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" (click)="hideErroMsg()">Have a coupon code?</a>
								<div class="collapse multi-collapse" id="multiCollapseExample1">
									<div class="input-group">
									<input type="text" class="form-control coupon" maxlength="20" placeholder="Coupon code" type="text" value="" [(ngModel)]="couponcode" required>
										<span class="input-group-append">
											<button class="btn btn-primary btn-apply coupon" [disabled]="couponcode === ''" (click)="applyCouponCode()">{{couponBtnText}}</button>
										</span>
									</div>
									<p *ngIf="showCouponCodeError" class="coupon-error-txt">{{couponCodeErrorMsg}}</p>
								</div>
								</td>
							</tr>
							<hr style="width: 130%;">
							<tr class="order-total">
								<th>Total</th>
								<td data-title="Subtotal">
									<span class="woocommerce-Price-amount amount">{{selectedMembership.amount_text.USD.now}}</span>
								</td>
							</tr>
						</tbody>
					</table>
					<a href="javascript:;" class="button payment-btn" (click)="createRzpayOrder(selectedMembership.amount.USD, selectedMembership)">Continue Payment</a>
				</div>

				<div class="">
					<p class="small" style="font-size: 12px; margin-top: 6px;">By confirming your subscription, you allow Crafttor to charge your card for this payment in accordance with their terms.</p>
				</div>
			</div>
		</div>

		<div class="loader-scenarios" *ngIf="showTopRightLoader">
			<img class="img-up-loader" style="width: 30px;" src="assets/img/img-up.svg" alt="">
		</div>

		<div class="loader-scenarios-success-msgs" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" *ngIf="sendSuccessful">
			<span class="ant-avatar-selected">
				<svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 5L4.58824 9L13 1" stroke="#0fa636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</span>
			<p>{{successfullMessage}}</p>
		</div>

		<div class="loader-scenarios-error-msgs" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" *ngIf="sendSuccessfulErrorMsg">
			<p>{{sendSuccessfulErrorMessage}}</p>
		</div>
	</div>
</section>

<section class="cart_sec" [hidden]="!payNow">
	<div class="container" style="max-width: 500px;">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper" style="max-width: 500px; padding-bottom: 60px;">
					<a href="javascript:" class="back-link" (click)="paymentSelected=true;mainSection=false;payNow=false;"><img src="assets/img/back-arrow.svg"> Back</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- FAQs Section -->
<section class="faq-section-other-pages" style="margin-top: 120px;">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is there any offer or a free trial?</h2>
					<p>Yes, we do have a free plan & offers going on. In fact, we are a step ahead. You may use our graphic assets free of cost for personal or commercial purposes but there is a limit in downloading the assets. Also we do have an offer going on yearly plans for both Individual and Premimun.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which plan should I choose?</h2>
					<p>We have one free and two paid plans going on. Free plan has a per day download limit and paid plans like individual & premium also have download limits but there is no daily download limit. You can check out https://crafttor.com/member for more details about all the plans.</p>
					<hr>
				</div>
			</div>

			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which membership is more beneficial?</h2>
					<p>Benefits of every membership is based on customer's requirements. If anyone wants to download less PNG & SVG with 3D illustrations then Individual is the plan and if requirement of download is more then Premium plan is a good option with one custom illustration by us.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>What is custom illustration in Premium plan?</h2>
					<p>For our premium members this is a special add-on in the plan. For premium members we will design a custom illustration as per their requirements. A custom illustration can be based on any topic with different sizes as required. We will provide the custom illustration in PNG & SVG format only.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->

<!-- {%popup content} -->
<ng-template #paymentSuccess>
	<!-- <div class="modal fade product_view" id="product_view_second"> -->
	<div class="modal-dialog modal-dialog-centered" role="document" style="margin-top:auto; margin-bottom: auto;">
		<!-- <div class="modal-dialog"> -->
		<div class="modal-content">
			<div class="modal-header" style="display: block; border-bottom: none;">
				<a (click)="closeSuccessModal()" data-dismiss="modal" class="class pull-right">
					<img  style="display: block; float: right" src="assets/img/cross.svg">
				</a>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="container">
						<div class="col-md-12 product_img">
							<div class="popup_wrapper">
								<img src="assets/img/gift.png" style="width: 200px; height: 180px; display: block; margin-left: auto; margin-right: auto;width: 50%;">
								<div class="title">
									<h3 style="text-align:center; margin-top:30px; font-weight: 600">Your Payment is successfull</h3>
								</div>
								<!-- <p style="text-align:center;">Your membership isn't activated(Technical issue).</p> -->
								<p style="text-align:center; margin-top:16px;">Happy Downloading!!</p>
								<a href="javascript:;" class="button" style="width: 100%" (click)="closeSuccessModal(); router.navigate(['/search'])">Search Illustrations</a>
								<p style="text-align:center; margin-top:6px;"><small>For any further assistance email us: <a href="mailto:info@crafttor.com"><b>info@crafttor.com</b></a></small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</ng-template>

<div
	style="width: 100%;
	background-color: rgba(0,0,0, .9);
  padding-top:200px;"
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':showloaderStyle}">
	<div class="modal-dialog-style modal-dialog-centered-style" role="document" style="height: 100px;
    width: 25%; margin: 0 auto; text-align: center;">
		<div class="modal-content" style="background-color: rgb(255,255,255); border-radius: 10px;">
			<div class="modal-body">
				<img class="main-loader-img" style="width: 140px;" src="assets/img/loader.svg" alt="">
			  <h6>Please wait</h6>
			  <p>payment processing...</p>
			</div>
		</div>
	</div>
</div>

<ng-template #loginmodal let-modal>
	<div class="modal-dialog modal-dialog-centered login-modal home-login-modal" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations" (click)="openClose()">
			</button>
			<div class="modal-body">
				<h3>{{loginSignUpText}}</h3>
				<!-- <p style="margin-bottom:30px; margin-top:8px;">Please login to download more illustrations</p> -->
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<!-- <a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a> -->
					<!-- <a href="javascript:;" class="button twitter-btn"><i class="fa fa-twitter"></i> Continue with Twitter</a> -->
					<a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>

					<div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>

					<div class="container" *ngIf="login">
						<form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
							Email is not valid
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
							Password is required
						</span>

							<label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
							clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

							<p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
							<!-- <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a> -->

							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

						<div class="container" *ngIf="emailVerify">
							<form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
						Email is not valid
					</span>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>

					<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
					<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
					</div>

					<div class="container" *ngIf="signup">
						<form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
							<input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
							<input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
							<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
							First Name is required
						</span>
							<span class="text-danger error-text" style="float: right;"
							*ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
							Last Name is required
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
							Password is required
						</span>

						<input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
							OTP is required
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

						<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
				</div>

				<div class="container" *ngIf="forgotpassword">
					<form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
						<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
							Email is not valid
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>
						<p class="below-txt"><a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Return to Login</a></p>
						<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
						</form>
					</div>

					<div class="container" *ngIf="enterOtp">
						<form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
							<input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
							<span class="text-danger error-text"
							*ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
							Enter OTP
						</span>

						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
						Enter new password
					</span>
					<input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
					<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
						Re-enter password
					</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>
							<p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
