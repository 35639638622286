<!-- Hero Section -->
<section class="hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="inner-content">
					<h1 style="font-size: 56px;">Empowering Excellence, Inspiring Innovation</h1>
					<p>Crafttor's goal is to give designers a clear pixel platform for their innovations.</p>
					<div class="btn-wrapper">
						<a href="javascript:;" routerLink="/member" class="button">Become Member</a>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="img-box">
					<img src="assets/img/about-us.svg" alt="download gif illustration">
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Moving Logos  Section -->
<section class="moving-section">
	<div class="sec-title">
		Trusted by
	</div>
	<div class="moving-logo-wrapper">
		<ul>
			<li>
				<img src="assets/img/airtel.svg" alt="">
			</li>
			<li>
				<img src="assets/img/licious.svg" alt="">
			</li>
			<li>
				<img src="assets/img/byju.svg" alt="">
			</li>
			<li>
				<img src="assets/img/wheels.svg" alt="">
			</li>
			<li>
				<img src="assets/img/deloitte.svg" alt="">
			</li>
			<li>
				<img src="assets/img/flip.svg" alt="">
			</li>
			<li>
				<img src="assets/img/innovaccer.svg" alt="">
			</li>
			<li>
				<img src="assets/img/thoughtworks.svg" alt="">
			</li>
			<li>
				<img src="assets/img/yellow.ai.svg" alt="">
			</li>
			<li>
				<img src="assets/img/airtel.svg" alt="">
			</li>
			<li>
				<img src="assets/img/licious.svg" alt="">
			</li>
			<li>
				<img src="assets/img/byju.svg" alt="">
			</li>
			<li>
				<img src="assets/img/wheels.svg" alt="">
			</li>
			<li>
				<img src="assets/img/deloitte.svg" alt="">
			</li>
			<li>
				<img src="assets/img/flip.svg" alt="">
			</li>
			<li>
				<img src="assets/img/innovaccer.svg" alt="">
			</li>
			<li>
				<img src="assets/img/thoughtworks.svg" alt="">
			</li>
			<li>
				<img src="assets/img/yellow.ai.svg" alt="">
			</li>
		</ul>
	</div>
</section>

<!-- <section class="designed-area">
	<div class="container">
		<h2 class="team-head-title">Get to know us</h2>
		<div class="row">
			<div class="col-md-3">
				<div data-aos="fade-right" class="text-center" (click)="openWorkspace()">
					<img src="assets/img/workspace.jpg" alt="" width="150" style="border-radius:5%;">
					<h3 class="">LEADERSHIP</h3>
					<span>At Crafttor, our success is a reflection of the strong leadership team that steers our ship towards innovation, growth, and unparalleled success. </span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="text-center" routerLink="/marketplace">
					<img src="assets/img/hero-img.gif" alt="" width="150" style="border-radius:5%;">
					<h3 class="">PARTNERSHIPS</h3>
					<span>From DAM to Illustrations to platform partnership, we work with top leaders to provide strong managementa dn digtal data.</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="text-center" routerLink="/marketplace">
					<img src="assets/img/hero-img.gif" alt="" width="150" style="border-radius:5%;">
					<h3 class="">CAREERS</h3>
					<span>From DAM to Illustrations to platform partnership, we work with top leaders to provide strong managementa dn digtal data.</span>
				</div>
			</div>
			<div class="col-md-3">
				<div class="text-center" routerLink="/marketplace">
					<img src="assets/img/hero-img.gif" alt="" width="150" style="border-radius:5%;">
					<h3 class="">CAREERS</h3>
					<span>From DAM to Illustrations to platform partnership, we work with top leaders to provide strong managementa dn digtal data.</span>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class="community-section" id="plugin-update-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h3>Transforming Ideas into Achievements</h3>
				<p>Helping Businesses through the power of illustrations and assets management.</p>
      </div>

			<ul>
        <li>
          <div class="plugins-data">
            <!-- <h4>Figma</h4> -->
            <h1 class="figma-count-txt">7+</h1>
            <h4>Products</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
            <!-- <h4>Sketch</h4> -->
  					<h1 class="sketch-count-txt">500+</h1>
  					<h4>Illustrations</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
            <!-- <h4>Marketplace</h4> -->
  					<h1 class="illustrator-count-txt">50K+</h1>
  					<h4>Users</h4>
          </div>
        </li>
      </ul>
		</div>
	</div>
</section>

<section class="about-us-solving-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="img-box" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
					<img src="assets/img/code.svg" alt="about-us illustration">
				</div>
			</div>
			<div class="col-sm-1">
			</div>
			<div class="col-sm-5">
				<h3>Crafttor workplace</h3>
				<p>It's important to create a culture of innovation and we are passionate about the team culture and grow together, and remain positive to inspires everyone who is connected with us.
				Our best part is to deliver the awesome & out of the box concepts.
				<br>
				Being focused and accomplishing our goals is what makes Crafttor stand at a position of providing variations.
				</p>
			</div>
		</div>
	</div>
</section>

<section class="about-us-team-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-5">
				<h3>Top Team</h3>
				<p>A good team and smooth flow of teamwork is indeed the key to rapid growth and triumph! We at Crafttor are always keen to make a difference through our products, so designers need not put in any extra effort while working on deliverables. For us the strength of a team is each individual member. The strength of each member is the team.
				</p>
			</div>
			<div class="col-sm-1">
			</div>
			<div class="col-sm-6">
				<div class="img-box" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
					<img src="assets/img/team.svg" alt="about-us illustration">
				</div>
			</div>
		</div>
	</div>
</section>

<section class="team-carousel">
	<div class="pink-background"></div>
	<div class="carousel-container">
		<h5 class="carousel-heading">Our team of global</h5>
		<div class="carousel-subheading-back">
			<h2 class="carousel-subheading">Designers and Developers</h2>
		</div>
		<div class="carousel-wrapper-container">
			<div class="carousel-wrapper" [ngStyle]="{'transform': 'translateX(-' + currentIndex * (100 / visibleItems) + '%)'}">
				<div class="carousel-main-item" *ngFor="let item of items">
					<div class="carousel-item-content">
						<img class="user-image" [src]="item.image" alt="User Image" />
						<div class="carousel-text">
							<h3 class="user-name">{{ item.name }}</h3>
							<p class="user-designation">{{ item.designation }}</p>

							<hr>

							<div class="exp-cert">
								<img src="assets/img/exp.svg" alt="Experience Icon">
								<span class="user-designation-img">{{ item.exp }}</span>
							</div>

							<div class="exp-cert">
								<img src="assets/img/cert.svg" alt="Certification Icon">
								<span class="user-designation-img">{{ item.cert }}</span>
							</div>

						</div>
					</div>
				</div>
			</div>
			<button class="nav-team-button left" *ngIf="currentIndex > 0" (click)="prevSlide()">&#10094;</button>
			<button class="nav-team-button right" *ngIf="currentIndex < items.length - visibleItems" (click)="nextSlide()">&#10095;</button>
		</div>
		<img class="side-image" src="assets/img/team-art.svg" alt="Side Image" />
	</div>
</section>

<section class="about-us-solving-section-bottom-txt">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h6>We are designers, illustrators, techies, developers.<br> We create digital products that makes your design workflow faster & easier.</h6>
			</div>
		</div>
	</div>
</section>

<section class="download-figma text-center">
	<div class="container">
		<div class="btn-wrapper">
		<a href="javascript:;" routerLink="/member" class="button">Become Member</a>
		</div>
	</div>
</section>
